/* Scoped styles to a specific class to avoid global effects */
#dashboard-root {
    font-family: arial, sans-serif;
    color: white;
    background-color: purple;
  }
  
  #dashboard-root table {
    border-collapse: collapse;
    width: 100%!important;
    background-color: black;
    color: white;
  }
  
  #dashboard-root td, #dashboard-root th {
    text-align: left;
    padding: 8px;
    font-size: 1.5rem;
  }
  
  #dashboard-root thead {
    background-color: #473333;
  }
  
  #dashboard-root table#main-table>tbody>tr:nth-child(odd) {
    background-color: #968e8e;
  }
  
  #dashboard-root table#main-table>tbody>tr:nth-child(even) {
    background-color: #545252;
  }
  
  #dashboard-root table#add-student-table>thead>tr>td>input {
    width: inherit;
  }
  
  #dashboard-root div#add-student-div {
    margin-bottom: 1rem;
  }
  
  #dashboard-root input[type='checkbox'] {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  #dashboard-root h1 {
    text-align: center;
    color: white;
    padding: inherit;
  }
  
  #dashboard-root button.editStudent {
    height: 2rem;
    width: 2rem;
    padding: 0; /* Remove default button padding */
    background-color: transparent; /* Set button background color to transparent */
    border: none; /* Remove button border */
  }
  
  #dashboard-root button.editStudent img {
    height: 100%;
    width: 100%;
    object-fit: cover; /* Ensure the image covers the entire button */
  }
  
  #dashboard-root .w20 {
    width: 20rem;
  }
  
  #dashboard-root .w18 {
    width: 18rem;
  }
  
  #dashboard-root .w10 {
    width: 10rem;
  }
  
  #dashboard-root .w13_5 {
    width: 13.5rem;
  }
  
  #dashboard-root .w6 {
    width: 6rem;
  }
  
  #dashboard-root .w8 {
    width: 8rem;
  }
  
  #dashboard-root .w7 {
    width: 7rem;
  }
  
  #dashboard-root .w5 {
    width: 5rem;
  }
  
  #dashboard-root .w2 {
    width: 2rem;
  }
  
  #dashboard-root .w3 {
    width: 3rem;
  }
  
  #dashboard-root .w4 {
    width: 4rem;
  }
  
  #dashboard-root .w15 {
    width: 15rem;
  }
  
  #dashboard-root button>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0px;
  }
  
  #dashboard-root div#updateDiv>button {
    padding: 0px;
  }
  
  #dashboard-root table {
    width: 100%;
    table-layout: fixed;
  }
  
  #dashboard-root td {
    width: 50%;
    padding: 5px;
    box-sizing: border-box; /* Include padding and border in the width calculation */
  }
  
  #dashboard-root input[type="text"] {
    width: 100%;
    box-sizing: border-box;
  }
  
  #dashboard-root input::placeholder {
    color: #00000064;
    font-size: large;
  }
  
  #dashboard-root tbody>tr>td>input {
    background-color: #00000052;
    font-size: large;
    color: white;
  }
  
  #dashboard-root div#addRemoveStudents-div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 0.3rem;
  }
  
  #dashboard-root button#add-student-btn {
    background-color: green;
    border: none;
    padding: 1rem;
    width: 7rem;
  }
  
  #dashboard-root div#updateDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 0.3rem;
  }
  
  #dashboard-root div#updateDiv>button {
    background-color: transparent;
    border: none;
  }
  
  #dashboard-root button>img {
    background-color: transparent;
    width: 100%;
    height: 100%;
  }
  
  #dashboard-root button#delete-students {
    background-color: red;
    border: none;
    padding: 1rem;
  }
  