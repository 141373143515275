/* Scope styles to a specific class to avoid global effects */
.login-root {
    background-color: rgb(54, 54, 164);
    color: white;
    padding: 1em;
    border-radius: 5px;
  }
  
  .login-root h1 {
    padding: 1em;
  }
  
  .login-root form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .login-root form > * {
    margin: 0.5em;
    margin-left: 1em;
  }
  
  .login-root form > input {
    max-width: 20em;
  }
  
  .login-root form > label {
    max-width: fit-content;
    max-height: fit-content;
    font-size: larger;
    font-weight: bold;
  }
  
  .login-root form > button {
    margin-top: 0.5em;
    max-width: fit-content;
    max-height: fit-content;
  }
  